export const selectProperty = {
  order: 7,
  name: "Selecting and removing diagram properties",
  keywords: "add switch view multiple",
  subcategory: "Properties",
  markdown: `# Selecting and removing diagram properties

  In a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}, the {% inlineRouterLink articleId="what-is-a-property?" %}property{% /inlineRouterLink %} can be selected separately from its parts. When {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" sectionName="View multiple properties" %}viewing multiple properties{% /inlineRouterLink %}, you can easily remove properties that you no longer want to see.

  ## Select a property

  ### From the diagram

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. Click the name of a property from the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Property selector" %}property selector section{% /inlineRouterLink %} of the diagram.
  {% /tablessContainer %}

  ### From Mini Map

  To select a nearby property from the {% inlineRouterLink articleId="the-role-of-the-map" sectionName="Mini Map" %}Mini Map{% /inlineRouterLink %}, {% inlineAppIcon iconName="cursor" /%}left click on a {% inlineRouterLink articleId="map-markers" %}map marker{% /inlineRouterLink %}.

  ![Mini Map select a property GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ### From Compact Map

  To select a nearby property from the {% inlineRouterLink articleId="the-role-of-the-map" sectionName="Compact Map" %}Compact Map{% /inlineRouterLink %}, {% inlineAppIcon iconName="cursor" /%}left click on a {% inlineRouterLink articleId="map-markers" %}map marker{% /inlineRouterLink %} or use the {% inlineAppIcon iconName="rectangleStack" /%}**view diagram** button {% inlineRouterLink articleId="map-markers" sectionName="Property markers" %}marker's details pop-up{% /inlineRouterLink %}.

  ![Mini Map select a property GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="tip" %}
  **Tip:** If the property is already in the diagram, focus on it with the the {% inlineAppIcon iconName="cursor" /%}**focus in diagram** button {% inlineRouterLink articleId="map-markers" sectionName="Property markers" %}marker's details pop-up{% /inlineRouterLink %}.
  {% /callout %}

  ### What happens

  The property name displays an orange background. The property {% inlineRouterLink %}datapoint{% /inlineRouterLink %} appears in the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, and the property's {% inlineRouterLink articleId="map-markers" %}map marker{% /inlineRouterLink %} turns orange and pulses.

  ![Select a property GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## View multiple properties

  One way that the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} differs from traditional stacking plans is that it can display multiple properties side by side. This makes it easy to compare assets within a submarket or even across markets.

  ### Using Mini Map

  To add a property to the diagram from {% inlineRouterLink articleId="the-role-of-the-map" sectionName="Mini Map" %}Mini Map{% /inlineRouterLink %}, {% inlineKeyboardShortcut %}Ctrl{% /inlineKeyboardShortcut %} + {% inlineAppIcon iconName="cursor" /%}left click on a {% inlineRouterLink articleId="map-markers" %}map marker{% /inlineRouterLink %}.

  ![Mini Map view multiple properties GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ### Using Compact Map

  To add a property to the diagram from {% inlineRouterLink articleId="the-role-of-the-map" sectionName="Compact Map" %}Compact Map{% /inlineRouterLink %}, use the {% inlineAppIcon iconName="squaresPlus" /%}**add to diagram** button in the {% inlineRouterLink articleId="map-markers" sectionName="Property markers" %}marker's details pop-up{% /inlineRouterLink %}.

  ![Mini Map select a property GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Remove a property

  To use this feature, you must be {% inlineRouterLink articleId="selecting-and-removing-diagram-properties" sectionName="View multiple properties" %}viewing multiple properties in the diagram{% /inlineRouterLink %}.

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. Click the {% inlineAppIcon iconName="xMark" /%}**close property button** to the right of the property's name in the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Property selector" %}property selector section{% /inlineRouterLink %} of the diagram.
  ![Remove a property GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}
  `,
};
